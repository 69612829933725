import { useState } from 'react';
import { Button } from 'src/shared/ui/_buttons/Button';
import { DatePickerTitleButton } from 'src/shared/ui/_inputs/date_pickers/DatePickerTitleButton';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import s from './SubHeader.module.scss';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { SendEmailMC } from '../SendEmailMC';

export const SubHeader = () => {
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());

	// * Modals
	const [modalSendEmail, setModalSendEmail] = useState<boolean>(false);
	const toggleModalSendEmail = () => setModalSendEmail(prevState => !prevState);

	// * Render
	return (
		<>
			<ModalNewProto
				isOpen={modalSendEmail}
				onClose={toggleModalSendEmail}
			>
				<SendEmailMC toggleModal={toggleModalSendEmail} />
			</ModalNewProto>

			<div className={s.container}>
				<DatePickerTitleButton // TODO
					prefix="TODO"
					selectedDate={selectedDate}
					setSelectedDate={setSelectedDate}
					format="year"
					size="large"
				/>

				<div>
					<Button>Внести планируемый отпуск</Button>

					<DropDownMenu // TODO
						items={[
							{
								title: 'Настроить группу',
								action: () => console.log('Настроить группу'),
							},
							{
								title: 'Отправить e-mail',
								action: toggleModalSendEmail,
							},
							{
								title: 'Выгрузить в Excel',
								action: () => console.log('Выгрузить в Excel'),
							},
							{
								title: 'Утвердить план',
								action: () => console.log('Утвердить план'),
							},
						]}
					/>
				</div>
			</div>
		</>
	);
};
