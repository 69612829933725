import { FC } from 'react';
import { PageDecorator } from 'src/app/decorators/page_decorators';
import { Content } from '../Content';
import { Header } from '../Header';

export const GroupPage: FC = () => {
	// * Render
	return (
		<PageDecorator>
			<Header />
			<Content />
		</PageDecorator>
	);
};
