import { Heading } from 'src/shared/ui/Heading';
import s from './SendEmailMC.module.scss';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';

interface Props {
	toggleModal: () => void;
}

export const SendEmailMC: React.FC<Props> = props => {
	const { toggleModal } = props;

	const onSaveClick = () => {
		// TODO
	};

	const onCancelClick = () => {
		toggleModal();
	};

	// * Render
	return (
		<div className={s.container}>
			<Heading level={2}>Отправить e-mail</Heading>

			<h4>Письмо</h4>

			<h4>Адресаты</h4>

			<ButtonPair
				primaryText="Сохранить"
				primaryOnClick={onSaveClick}
				primaryIsLoading={false} // TODO
				primaryDisabled={false} // TODO
				secondaryText="Отмена"
				secondaryOnClick={onCancelClick}
				secondaryIsLoading={false} // TODO
			/>
		</div>
	);
};
