import { routerPath } from 'src/app/router/paths';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';

export const Header = () => {
	return (
		<PageTitle
			title="TODO"
			goBackRoute={{
				text: 'Планировщик отпусков',
				route: routerPath.vacation.page,
			}}
		/>
	);
};
