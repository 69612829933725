import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import s from './Content.module.scss';
import { LineTabs } from 'src/shared/ui/_tabs/lineTabs';

const TABS = [
	{
		id: 0,
		name: 'Список сотрудников',
	},
	{
		id: 1,
		name: 'Категории',
	},
	{
		id: 2,
		name: 'Группы',
	},
	{
		id: 2,
		name: 'Команды',
	},
];

export const Content = () => {
	// * Render
	return (
		<div>
			{/* <LineTabs tabs={TABS} /> */}

			<div className={s.content}>
				<AddButton title="TODO" />
			</div>
		</div>
	);
};
